* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.c-contanier {
    width: 100%;
    height: auto;
    padding-top: 30px;
    background-color: rgb(234, 245, 253);
    color: white;
    background-repeat: no-repeat;
}

.min-circal-box {
    width: 250px;
    height: 150px;
    padding-top: 30px;

    margin-left: 10px;
    text-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    border: 5px solid rgb(0, 81, 13);
}

.min-circal-box>:nth-child(1) {
    box-sizing: border-box;
    font-size: 30px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: rgb(0, 81, 13);
}

.contanier-text-img {
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 10px;
}

.min-circal-box p {
    font-size: 18px;
    font-family: sans-serif;
    font-weight: 400;
    word-spacing: normal;
    color: rgb(0, 81, 13)
}

.Name-Aadmey {
    width: 50%;
    height: auto;
    position: absolute;
    background-color: rgb(247, 170, 71);
    border: 2px solid green;
    border-radius: 20px;
    left: 5%;
    padding: 10px 0 20px;
    margin-top: 40px;
    transform: rotate(2deg)
}

.Name-Aadmey>:nth-child(1) {
    text-align: center;
    align-items: center;
    font-size: 80px;
    color: white;
    font-family: fantasy;
    text-shadow: 100px blue;
    text-shadow: 5px 5px black;

}

.background-img {
    width: 45%;
    margin-left: 50%;
    margin-top: -5%;
    height: auto;
    border-radius: 50%;
    border: 6px solid green;
    box-shadow: 0px 5px 5px 0px green;
    /* background-image: url(../../img/download.jpg) */
}

.img-only img {
    width: 100%;
    height: 88vh;
    border-radius: 50%;
}



.c-start {
    height: 56px;
    min-width: 146px;
    color: #fff;
    text-align: center;
    background-color: #705cf6;
    border-radius: 100px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto 40px;
    padding: 10px 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    transition: all .2s;
    display: flex;

    border: none;
    margin-top: 20px;

}

.c-start:hover {
    background-color: #3898ec;
}

.a {
    color: rgba(255, 255, 255, .5);
    font-size: 14px;
}

@media only screen and (max-width: 1030px) {
    .contanier-text-img {
        display: flex;
        width: 100%;
        height: auto;
        padding-top: 10px;
    }

    .Name-Aadmey {
        width: 50%;
        height: auto;
        align-items: center;
        text-align: center;
    }

    .Name-Aadmey>:nth-child(1) {
        font-size: 55px;
        margin-top: 0;
        font-family: sans-serif;
    }



    .img-only img {
        height: 500px;
    }
}

@media only screen and (max-width: 820px) {
    .contanier-text-img {
        padding: 0;
    }

    .Name-Aadmey {
        position: relative;
        height: 30vh;
        left: 0;
        width: 600px;
    }

    .background-img {
        width: 300px;

        margin: 0;
        padding: 0;
        left: 0;
        right: 0%
    }
}

@media only screen and (max-width: 620px) {
    .contanier-text-img {
        height: auto;
        display: block;

    }



    .Name-Aadmey>:nth-child(1) {
        font-size: 40px;


    }

    .img-only img {
        height: 400px;
    }

    .background-img {
        width: 400px;
        height: auto;

    }

    .Name-Aadmey {
        position: absolute;
        height: auto;
        width: 99%;
        background-color: rgb(195, 143, 59, 0.8);
    }
}

@media only screen and (max-width: 420px) {
    .contanier-text-img {
        height: auto;

    }

    .Name-Aadmey {
        position: absolute;
        height: auto;
        width: 99%;
        margin-top: 10px;
        background-color: transparent;
        border: 0;
        transform: rotate(0);

        /* background-color: rgb(195, 143, 59, 0.7); */
    }

    .Name-Aadmey>:nth-child(1) {
        font-size: 34px;
        margin-top: 0%;
        color: white;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;


    }



    .img-only img {

        width: 100%;
        height: 300px;
        border-radius: 0;
        padding-left: 0;
    }

    .background-img {
        width: 99%;
        height: auto;
        border: 0;
        margin-top: 20px;
        border-radius: 0;
        margin-left: 0;
        padding-left: 0;

    }

    .c-start {
        color: white;
        font-size: 18px;
        text-shadow: 2p 2px black;
        font-family: cursive;
        word-spacing: 2px;
        width: 70%;
        height: 50px;
        margin-top: 50px;
        position: relative;
        border: 2px solid blue;
        background-color: blue;
    }




}