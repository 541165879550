.container-fee {
    width: 100%;
    height: 200vh;
    background: linear-gradient(rgb(197, 198, 198), rgb(223, 236, 249));
}
.MAIN-CONTANIER {
    width: 100%;
    height: 500px;
    text-align: center;
    margin: 0%;
    padding: 50px;
    transform: matrix(2px);
    background-image: url(/src/img/featured-image-1.png);

    
} 
.text h1 {
font-size: 100px;
color: white;
font-weight: bold;
font-family: sans-serif;
text-shadow: 5px 5px  black;
margin: auto;
}
.text span {
    font-size: 34px;
    color: black;
    text-shadow: 2px 2px 2px whitesmoke;
}
.heanding {
    width: 100%;
    height: 150px;
    font-size: 24px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 40px;
}
.heanding h2 {
    font-size: 30px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    color: #000000;
}
.fee_card {
    width: 100%;
    height: auto;
}
.basic {
    width: 100%;
    height:80px;
    border: 2px solid green;
    background-color: black ;
    margin: 0;
    text-align: center;
    
}
.basic h3 {
    font-size: 30px;
    color: white;
    text-shadow: 2px 2px black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
}
.men-fees-box {
    display: flex;
    width: 80%;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: space-around;
  
  
}
.card_1 {
    width: 30%;
    height: 700px;
     margin-top: 0;
    background-color: white;
    box-shadow: 2px 2px burlywood;
   
    }
    .card_1 h2 {
        margin-top: 40px;
        font-size: 40px;
        font-weight: bold;
        color: black;
        font-family: fantasy;
        text-shadow: 5px 5px white;
        margin-left: 100px;
       
    }
    .card_1 ul {
        margin-top:20px;
        
        padding: 60px;
    }
    .card_1 ul li {
        margin-top: -20px;
        padding-left: 10px;
        flex: auto;
        font-size: 16px;
       color: #000000;
       font-family: sans-serif;
        align-tracks: calc(30deg);
        list-style-image: url(../../img/icons8-tick-24.png);
    }
    
     
    .card_1 hr  {
        margin-top: 10px;
        border: 1px solid red;
    }
.card_2 {
    width: 30%;
    height: 90%;
    margin: auto;
    background-color: white;
    box-shadow: 2px 2px burlywood;
    padding: 20px;
   
}

.card_3 {
    width: 30%;
    height: 90%;
    margin: auto;
    background-color: white;
    box-shadow: 2px 2px burlywood;
    padding: 20px;
    
}
.card_3 h1 {
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
} 
.card_2 h1 {
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
} 
.card_1 h1 {
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
} 
.addToREgister {
    width: 80%;
    height: 50px;
    color: black;
    border: 2px solid burlywood;
    margin: auto;
   
    text-align: center;
    align-items: center;
    padding: 14px;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: bold;
    
}
.addToREgister a {
    color: black;
}

.addToREgister:hover {
    background:black;
   color: white;
}
.addToREgister1 {
    width: 80%;
    height: 50px;
    color: black;
    border: 2px solid burlywood;
    margin: auto;
    
    text-align: center;
    align-items: center;
    padding: 14px;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: bold;
    
}
.addToREgister1 a {
    color: black;
}

.addToREgister1:hover {
    background:black;
   color: white;
}
.addToREgister2 {
    width: 80%;
    height: 50px;
    color: black;
    border: 2px solid burlywood;
    margin: auto;
  
    text-align: center;
    align-items: center;
    padding: 14px;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: bold;
   
}
.addToREgister2 a {
    color: black;
}

.addToREgister2:hover {
    background:black;
   color: white;
}   
.addToREgister a {
    text-decoration: none;
}
@media screen and (max-width:1190px){
    
    .men-fees-box {
        width: 100%;
        justify-content: space-around   ;
    }
    .card_1 {
        width: 30%;
        height: 700px;
   
       
       

    }
    .card_1 h2 {
        margin-top: 40px;
        font-size: 24px;
        font-weight: bold;
        color: black;
        font-family: fantasy;
        text-shadow: 5px 5px white;
        margin-left: 100px;
       
    }
}
@media screen and (max-width:950px){
    .container-fee {
      height: auto;
    }
    
    .card_1 {
        width: 400px;
       height: 700px;
      margin: auto;
       text-align: center;
       margin-top: 30px;
       

    }
   .men-fees-box {
    width: 100%;
    display: block;
   }
    .card_1 h2 {
        margin-top: 40px;
        font-size: 24px;
        font-weight: bold;
       color: red;
        font-family: fantasy;
        text-shadow: 5px 5px white;
        margin-left: auto;
       
       
    }
    .addToREgister1{
        margin-top: -30px;
    }
}
@media screen and (max-width:880px){
    .card_1 {
        width: 400px;
       height: auto;
        ;
       text-align: left;
        
    }
    .men-fees-box {
        width: 100%;
        display: block;
    }
    .container-fee{
        height: auto;
    }
    .card_1 h2 {
        margin-left: 18vw;
        color: black;
        font-size: 30px;
    }
    
    
}
@media screen and (max-width:750px){
    .text h1 {
        font-size: 80px;
}
}
@media screen and (max-width:580px) {
    .card_1 {
        width: 400px;
       height: 650PX;
       
        margin: auto;
       margin-top: 30px;
       text-align: left;

    }
    .card_1 h2 {
        margin-left: 25vw;
    }
    .text span {
        font-size: 24px;
    }
}
@media screen and (max-width:450px){
    .men-fees-box {
        width: 100%;
        justify-content: space-around   ;
    }
    .card_1 {
        width: 350px;
        margin: auto;
        margin-top: 20px;
    }
    .card_1 h2 {
        margin-left: 25vw;
        color: black;
        font-size: 30px;
    }
   .text h1 {
       font-size: 50px; 
} 
}
@media screen and (max-width:400px){
    
    .card_1 {
        width: 100;
        margin: auto;
        margin-top: 20px;
    }
    .card_1 h2 {
       text-align: center;
       margin-left: 0;
    }
    .text h1 {
        font-size: 40px;
}
.MAIN-CONTANIER {
    height: 300px;
}
}
@media screen and (max-width:300px){
    .text h1 {
        font-size: 20px;
}
.text span {
    font-size: 18px;
}
}