:root {
  --backtext_color: linear-gradient(90deg, #fb60d7, #eb69dc 15%, #dc5cff 30%, #c555fe 45%, #a02fff 60%, #7752ff 75%, #5f98fa 90%, #44beff);
  --background-button: #3898ec
}

.App {

  background-color: var(--backtext_color);
  padding: 0;

}