* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Gptmain-contanier {

    width: 100%;
    height: auto;
    margin: auto;
    padding: 20px;

    background: linear-gradient(rgb(223, 236, 249));



}

.Gptmain-contanier>:nth-child(1) {
    font-size: 60px;
    font-family: sans-serif;
    font-weight: 600;
    font-style: normal;
    color: black;
    margin-top: 5rem;

}

.container-courses {
    display: flex;
    justify-content: space-around;
}

.course-box1 {
    width: 30vw;
    height: auto;
    border-radius: 30px;
    text-align: left;
    margin-top: 20px;
    padding: 10px;
    align-items: center;
    border: 2px solid rgb(7, 83, 18);
    box-sizing: border-box;
    box-shadow: 3px 3px 3px 3px rgb(194, 142, 59);
    background: rgb(254, 254, 254)
}

.course-box1 ul {
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    font-style: normal;
    flex-direction: column;
    justify-content: flex-start;
    color: black;
    font-weight: bold;
    word-spacing: normal;
    padding-top: 10px;


}

.course-box1 ul li {
    padding-top: 10px;
}

.course-box1>:nth-child(1) {
    font-size: 22px;
    color: rgb(7, 83, 18);
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 20px;
    font-weight: 600;
}

.course-box2 {
    width: 80%;
    height: auto;
    border-radius: 30px;
    text-align: left;
    margin-top: 20px;
    padding: 10px;
    color: black;
    border: 2px solid rgb(7, 83, 18);
    box-sizing: border-box;
    margin-left: 150px;
    box-shadow: 5px 5px 3px 3px rgb(194, 142, 59);
    background: rgb(254, 254, 254)
}

.course-box2 ul {
    font-size: 18px;
    font-family: sans-serif;
    font-style: normal;
    flex-direction: column;
    justify-content: flex-start;
    color: black;
    font-weight: bold;
    word-spacing: normal;
    padding-top: 10px;


}

.course-box2>:nth-child(1) {
    font-size: 25px;
    color: rgb(7, 83, 18);
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    margin: 20px;
    font-weight: bold;
}

.course-box2 span {
    font-size: 25px;
    color: rgb(7, 83, 18);
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 20px;
    text-align: center;
    font-weight: 600;
}

@media screen and (max-width:1050px) {
    .course-box1>:nth-child(1) {
        font-size: 20px;
        color: green;
        font-family: sans-serif;
        margin: 10px;
    }

    .Gptmain-contanier>:nth-child(1) {
        font-size: 50px;
        margin-top: 20px;

    }

    .course-box1 ul li {
        padding-top: 10px;
        font-size: 18px;
    }

    .course-box1 {
        height: auto;
    }

    .course-box2 {
        height: auto;
        margin-left: 100px;
    }
}

@media screen and (max-width:820px) {
    .course-box1>:nth-child(1) {
        font-size: 15px;
        color: black;
        font-family: sans-serif;
        margin: 10px;
    }

    .Gptmain-contanier>:nth-child(1) {
        font-size: 40px;

    }

    .course-box1 ul li {
        padding-top: 10px;
        font-size: 14px;
    }
}

@media screen and (max-width: 740px) {
    .course-box1>:nth-child(1) {
        font-size: 16px;
        color: black;

    }

    .Gptmain-contanier>:nth-child(1) {
        font-size: 40px;


    }

    .course-box1 ul li {
        padding-top: 10px;
        font-size: 14px;
    }
}

@media screen and (max-width:640px) {
    .container-courses {
        display: none;
        display: block;

        justify-content: center;

    }

    .course-box1 {
        width: 100%;
        margin-top: 30px;
        box-shadow: 1px 2px 2px 1px rgb(194, 142, 59);

    }

    .course-box2 {
        width: 100%;
        margin-left: 0;
    }

    .course-box1>:nth-child(1) {
        color: green;
        font-size: 24px;
        padding-top: 20px;

    }

    .Gptmain-contanier>:nth-child(1) {
        font-size: 40px;
        color: black;


    }

    .course-box1 ul li {
        padding-top: 10px;
        font-size: 17px;
    }

    .Gptmain-contanier {
        padding: 0;
        margin: 0;
    }

    .Gptmain-contanier>:nth-child(1) {
        padding: 25px;
        font-size: 24px;
    }
}

@media screen and (max-width:420px) {
    .Gptmain-contanier {
        align-items: center;
        padding: 20px;
        text-align: center;
    }

    .Gptmain-contanier>:nth-child(1) {
        font-size: 24px;
        color: black;
    }

    .course-box2>:nth-child(1) {
        font-size: 24px;
        font-family: sans-serif;
        font-weight: bold;
    }
}