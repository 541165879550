* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

@font-face {
    font-family: sans-serif;
    src: url('../../img/logo.png');
}

.page {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url(../../img/istockphoto-1383966318-640x640.jpg); */
    background: linear-gradient(rgb(197, 198, 198), rgb(223, 236, 249));
}

.cover {
    background-image: url(../../img/featured-image-1.png);
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(100, 156, 156);
    display: flex;
    flex-direction: column;
    padding: 20px;

}

.alt-login {
    width: 80%;
    height: 3em;
    display: flex;
    justify-content: space-around;

}

.alt-login div {
    width: 45%;
    height: 90%;
    border-radius: 0.25rem;
    background-color: black;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.Google {
    background-image: url(../../img/download\ \(1\).png);
}

.facebooks {
    background-image: url(../../img/download\ \(3\).png);
}

input {
    border: none;
    background-color: rgb(229, 226, 226);
    height: 4em;
    width: 80%;
    border-radius: 0.25em;
    text-align: center;
    padding: 2em;

}

input:focus {
    outline-color: rgb(32, 177, 255);

}

.login-btn {
    width: 40%;
    height: 3em;
    background-color: rgb(32, 177, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border-radius: 0.25em;
    cursor: pointer;

}

.login-btn:hover {
    transform: scale(1.25);
}

.text {
    position: relative;
    bottom: -2em;

}

.hide {
    position: absolute;
    transform: scale(0);
}

.login-popup {
    position: absolute;
    height: 30em;
    width: 30em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    border-radius: 0.25em;
    background-color: black;
    color: white;
    bottom: 0;
    transform: scale(1);
    transition: 1.5s;
}

.tiwtter a {
    font-size: 2rem;
    font-weight: 300;
}

@media screen and (max-width:950px) {
    .cover {
        width: 100%;
    }

    .page {
        height: auto;
    }
}

/* .contact {
    width: 28vw;
    height: 40vh;
    background-color: white;
    position: relative;
    position: absolute;
} */

.text3 {

    width: 400px;
    height: auto;
    font-size: 18px;
    margin-top: 5rem;

}

.btn-2 {
    width: auto;
    background-color: transparent;
    color: white;
    border: none;
    margin-left: 4.5rem;
}

.Email {
    width: 400px;
    height: auto;
    margin-top: 2rem;
    color: black;
    font-size: 20px;
}


.Email a {
    color: white;

    margin-left: 4.5rem;
}

.facebook {
    margin-top: 2rem;
    width: 400px;
    height: auto;
    margin-top: 20px;
    color: black;
    font-size: 20px;

}

.facebook a a {
    color: white;
    margin-left: 5rem;

}

.intsagarm {
    margin-top: 20px;
}

.intsagarm a a {
    color: white;
    margin-left: 5rem;

}

.twitter-c a a {
    color: white;
    margin-left: 5rem;

}


.twitter-c {
    margin-top: 20px;
}

@media screen and (max-width:856px) {

    .text3 {
        font-size: 25px;
    }

    .facebook {
        font-size: 18px;
    }

    .Email {
        font-size: 18px;
    }

    .tiwtter a {
        font-size: 18px;
    }
}


@media screen and (max-width:600px) {

    .text3 {
        width: 100%;
    }

    .facebook {
        width: 100%;
    }

    .Email {
        width: 100%;
    }

    .intsagarm {
        width: 100%;
    }

    .twitter-c {
        width: 100%;
    }

    .tiwtter a {
        font-size: 18px;
    }
}