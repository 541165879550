.footer {
    width: 100%;
    height: auto;
    background-color: #f6f3ee;
    box-shadow: 2px 2px 2px 2px whitesmoke;

}

.foter-container {
    display: flex;
    background: #f6f3ee;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    width: 100%;
    height: auto;
    margin-top: 10px;
    align-items: center;
    box-shadow: 2px 2px 2px 2px whitesmoke;
}

.profile {
    display: flex;
    width: 100%;
    background-color: #f6f3ee;
    height: auto;
    justify-content: space-between;

}

.Gmail img {
    height: 40px;
}

.Gmail>:nth-child(2) {
    font-size: 18px;
    margin: 0;
    margin-top: 10px;
    font-family: sans-serif;
    font-weight: 400;
    color: black;
    margin-left: 5px;
}

.text2 {
    background-color: #f6f3ee;
}

.text2:nth-child(2) {
    font-size: 18px;
    color: black;
    font-weight: 600;
    width: 300px;
    background-color: #f6f3ee;
    border: none;
    font-family: sans-serif;

}

.whtasap-btn:hover {
    width: 40px;
}

.btn {
    width: 200px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.text2:nth-child(1) {
    height: 30px;

}

.iconlink .youtube>:nth-child(1) {
    height: 50px;
    color: green;
    background-color: green;
}

/* .whtasap-btn {
    display: flex;

} */

.logo-f img {
    height: 105px;
    position: relative;
    transform: scale(1);
}

@media screen and (max-width:560px) {
    .profile {
        display: block;
        width: 100%;

    }

    .text2 {
        align-items: flex-start;
    }

}