.from-u-e-m {
    width: 100%;
    height: auto;
   border-radius: 50px;
   background-color: white;
    display: flex;
    box-shadow: 10px,10px white;
 }
 .english-information {

    width: 50%;
    height: auto;
    box-shadow: 10px white;
    padding: 20px;
 }
 .english-information ul li {
    font-size: 18px;
    line-height: 3rem;
      
 }
.urdu-Admison-detial {
    width: 50%;
    height: auto;  
    padding: 20px;
    box-shadow: 5px white;
}
.urdu-Admison-detial ul li {
    font-size: 18px ;
    align-items: center;
    text-align: right;
    list-style: none;
    line-height: 3rem;

}
.btn-Register-form  { 
    width: 100% ;
    height:50px;
    align-items: center;
    text-align: center;
}
.btn-Register-form  button {
    width: 80%;
    height: 50px;
   border: 2px solid  rgb(148, 95, 29);

    margin: auto;
    font-size: 30px;
    color: rgb(148, 95, 29) ;
    font-weight: bold;
    border-radius: 50px;
    opacity: 0.5;
}
.btn-Register-form  button:hover {
    background-color: black;
    color: white;
    transition: all 1s;
    opacity: 1;
   
} 
.urdu-Admison-detial h1{
    text-align: right;
}
@media screen and (max-width:950px) {
    .from-u-e-m  {
        display: block;
        width: 100%;
        margin-top: 10px;
    }
    .english-information{
        width: 100%;
    }
    .english-information ul li {
        font-size: 16px;
    }
    .urdu-Admison-detial{
        width: 100%;
    }
    
    

}
@media screen and (max-width:590px){
    .english-information h1 {
        font-size: 20px;
        font-family: fantasy;
    } 
}