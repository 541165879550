* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 150px;
    background-color: rgb(223, 236, 249);
    color: white;
    z-index: 1;
    margin: 0;
}

.logo {
    font-size: 30px;
}

.nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 75%;

}

.home,
.Courses,
.Books,
.Contact,
.Singup {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    text-decoration: none;
}

.home:hover {
    color: rgb(148, 95, 29);
    font-weight: bold;
    text-decoration: none;
}

.Courses:hover {
    color: rgb(148, 95, 29);
    font-weight: bold;
    text-decoration: none;
}

.Books:hover {
    color: rgb(148, 95, 29);
    font-weight: bold;
    text-decoration: none;

}

.Contact:hover {
    color: rgb(148, 95, 29);
    font-weight: bold;
    text-decoration: none;
}


.Singup:hover {
    color: white;
    background: rgb(148, 95, 29);
    border: none;
    text-decoration: none;
    transition: all 0.6s;
}

.Singup {
    border: 1px solid yellow;
    border-radius: 5px;

}

.moblie-menu-icon {
    display: none;

}


@media screen and (max-width:856px) {
    .logo {
        display: flex;
        position: absolute;
        top: 15px;
        left: 35px;

    }

    .nav-links {
        display: none;
    }

    .nav-links-moblie {
        position: absolute;
        display: block;
        list-style: none;
        background-color: rgb(0, 84, 105, 0.3);
        left: 0;
        top: 150px;
        transition: all 0.5s ease-out;
        width: 100%;
    }

    .home,
    .Courses,
    .Books,
    .Contact,
    .Singup {
        color: white;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease;
    }

    .Singup {
        border: none;
    }

    .Singup:hover {
        background: transparent;
        color: rgb(148, 95, 29);
    }




    .moblie-menu-icon {
        display: block;
        position: absolute;
        font-size: 30px;
        color: black;
        background-color: hsl(210, 68%, 93%);
        border: none;
        outline: none;
        top: 15px;
        right: 25px;

    }
    .urdu-Admison-detial h1 {
       float: right;
       float: none;
    }
}