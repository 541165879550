.Videopass {
    width: 100%;
    height: auto;
    margin: auto;
    background: linear-gradient(rgb(223, 236, 249));
    align-items: center;

}



.VideoBox {
    width: 80%;
    height: 470px;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;

    box-shadow: 5px 5px 5px 5px rgb(194, 142, 59);

}

video .videopass-img {
    width: 100%;
    height: 500px;
    box-sizing: border-box;
}

/* .v-singup {
    height: 56px;
    min-width: 146px;
    color: #fff;
    text-align: center;
    background-color: rgb(197, 198, 198);
    border-radius: 100px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto 40px;
    padding: 10px 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    transition: all .2s;
    display: flex;
    position: static;
    border: none;
    margin-top: 20px;
} */

.v-singup:hover {
    background-color: #3898ec;
}

@media screen and (max-width:856px) {
    video .videopass-img {
        width: 100%;
        height: 400px;
        box-sizing: border-box;
    }
}

@media screen and (max-width:650px) {
    video .videopass-img {
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        background-image: none;
    }
}