.main-container {
    background: linear-gradient(rgb(197, 198, 198), rgb(223, 236, 249));
    ;
    width: 100%;
    height: auto;
    align-items: center;
    margin: 0 auto;
    justify-content: center;



}

.text-lo {
    color: white;
    font-size: 100px;
    font-weight: 400;
    margin: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.logocontainer {
    background-color: black;
    width: 80%;
    margin: 0 auto;
    height: auto;
}

.contanier {

    background-color: black;
    padding: 40px 0;
}


.contanier img {
    height: 32px;
    padding-left: 15px;
    align-items: center;
    margin: 0 auto;

}

.container-23logo {
    width: auto;
    height: 80vh;
    margin: auto;
    display: flex;

}

.book-box {
    margin-left: 1rem;
    height: auto;
    margin: 0 auto;
    background-color: aliceblue;
    border: 2px solid white;
    align-items: center;
}



.book-box>:nth-child(1),
.book-box>:nth-child(2),
.book-box>:nth-child(3),
.book-box>:nth-child(4),
.book-box>:nth-child(5),
.book-box>:nth-child(6) {
    height: 400px;
    align-items: center;
    margin: auto;
    box-shadow: 2px 2px 2px 2px white;


}

.book-box span {
    font-size: 24px;
    font-family: sans-serif;
    font-weight: 700;
    color: blueviolet;

}

.book-box {
    height: auto;
    width: auto;
    background-color: white;


}

@media only screen and (max-width: 750px) {
    .book-box {
        height: auto;
        width: auto;
        background-color: white;


    }
}