.btn-Register-form1 {
    width: 50%;
    height: auto;
    margin: auto;
    margin-top: 100px;
    
}
.Register-form1 {
    width: 100%;
    height: auto;
  
}

@media screen and (max-width:1200px){
    .Register-form1 {
        width: 100%;
        height: auto;
      
    } 
 .btn-Register-form1 {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 100px;
    
}
.btn-Register-form1 iframe {
    
}
}
@media screen and (max-width:670px){
    .Register-form1 {
        width: 100%;
        height: auto;
      
    } 
 .btn-Register-form1 {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 100px;
    
}
.btn-Register-form1 iframe {
    width: 100%;
    height: 1550px;
    
}
}