.continerbanerslider {
    width: 100%;
    height: auto;
    background-color: rgb(234, 245, 253);
    align-items: center;
    margin: 0 auto;

}

.d-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.p-2 {
    width: 90%;
    height: 430px;
    text-align: center;
    box-shadow: 2px 2px 2px 2px rgb(195, 145, 59);
    border: 2px solid rgb(0, 81, 13);
    background-color: rgb(254, 254, 254);
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.p-2 img {
    width: 300px;
    height: 400px;
    transform: scale(1);
}
 /* instarction css information from */
 @media screen and (max-width:980px) {
    .d-container {
        display: block;
        

    }

 }

@media screen and (max-width:780px) {
    .continerbanerslider {
        width: 100%;
    }
    
    .p-2 img {
        width: auto;
        height: 400px;
      

    }
    .p-2{
        display: block;
        height: auto;
        width: 80%;
        margin: auto;
       
    }

    .d-container {
        display: block;



    }

    .continerbanerslider {
        height: auto;
        justify-content: center;

    }

}

@media screen and (max-width: 530px) {
    .p-2 img {
        width: auto;
    }
    .p-2 {
        width: 100%;
    }

    
}

@media screen and (max-width: 550px) {}